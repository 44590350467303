/* eslint-disable react/no-danger */
/** @jsx jsx */
import {
  jsx,
} from 'theme-ui';
import Layout from 'components/layout';
import { Header1 } from 'components/primitives/text';
import { graphql } from 'gatsby';

const AboutPage = ({
  data: {
    pageContent,
  },
}: {
  data: {
    pageContent: {
      html: string;
      frontmatter: {
        title: string;
      };
    };
  };
}) => (
  <Layout title="Om oss">
    <Header1>{pageContent.frontmatter.title}</Header1>
    <div dangerouslySetInnerHTML={{ __html: pageContent.html }} sx={{ pb: 7 }} />
  </Layout>
);

export default AboutPage;

export const pageQuery = graphql`
query AboutQuery {
  pageContent: markdownRemark(fileAbsolutePath: {regex: "/pages.*\/about.md$/"}) {
    id
    html
    frontmatter {
      title
    }
  }
}
`;
